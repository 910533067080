.textarea {
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}


