
.advario-confirmation-section p {
  @apply text-sm;
}

.advario-confirmation-section span>div>div>div>div>p:first-child {
  @apply font-bold text-black !important;
}

.advario-confirmation-section button>div:first-child {
  /* @apply hidden; */
}
.advario-confirmation-section .table-cell button>div:first-child {
  @apply block;
}

.advario-confirmation-section button div {
  /* @apply p-2; */
}
/* 
.advario-confirmation-section div>svg:first-child {
  @apply bg-red-500;
}  */

.advario-confirmation-section>span>div>div {
  @apply py-4;
}

.advario-confirmation-section>span>div>div>p {
  @apply w-fit min-w-0;
}

.advario-confirmation-section input, 
.advario-confirmation-section textarea, 
.advario-confirmation-section button>div,
.advario-confirmation-section .form-sections input,
.advario-confirmation-section .form-sections textarea,
.advario-confirmation-section .form-sections button>div
{
  @apply text-sm shadow border-none rounded-md text-black bg-white;
}

.advario-confirmation-section .form-sections .table-row-group .table-cell,
.advario-confirmation-section .table-row-group .table-cell {
  @apply shadow border-none;
}