@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../font/Inter-VariableFont.ttf");
}

html,
body {
  @apply font-inter antialiased;
}

.btn-primary {
  @apply bg-sky-blue text-white;
}

.btn-secondary {
  @apply bg-sky-blue-light text-white;
}

.btn-primary-ghost {
  @apply border-sky-blue bg-none text-sky-blue;
}

.btn-secondary-ghost {
  @apply border-sky-blue-light bg-none text-sky-blue;
}

:disabled {
  cursor: not-allowed;
}

.react-datepicker__navigation--previous {
  @apply mt-2;
}

.react-datepicker__navigation--next {
  @apply mt-2;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--selected {
  @apply rounded-md text-white;
}

.react-datepicker__input-container [type=text] {
  @apply base-form-input;
}

.base-form-input {
  @apply shadow-sm border border-gray-200 leading-6 rounded-md text-sm placeholder:text-black-light placeholder:opacity-50 w-full disabled:bg-gray-100;
}

.base-form-input:focus {
  @apply border-sky-blue outline-none ring-1 ring-sky-blue ring-inset;
}

.overflow-overlay {
  overflow: overlay;
}

/* INPUT STYLES */
/* input:focus { 
  outline: none;
  border-color: "#0064ff";
} */

.no-focus-button:focus {
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

:focus {
  outline: none;
}
input {
  outline: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
