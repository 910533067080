@layer tiptap {
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    color: #8e8e8e;
    float: left;
    pointer-events: none;
    height: 0;
  }
  
  .ProseMirror {
    height: 100%;
  
  }
  
  .ProseMirror-focused {
    word-break: break-all;
  }
  
  .outer-editor {
    ul, ol {
      padding: 0 1rem;
    }
    
    ul {
      list-style: disc !important;
      width: 100%;
      list-style-position: outside !important;
      display: inline-block;
    }
    
    ol {
      list-style: decimal !important;
      width: 100%;
      list-style-position: outside !important;
      display: inline-block;
    }
    
    li {
      p {
        width: fit-content;
        display: inline-block;
      }
    }
  }
  
  .ProseMirror-TipTapInputGray {
    .ProseMirror {
     padding: 0.5rem 1rem;
    } 
    ::placeholder {
      color: red !important;
    }
  }


}
